const box = {
  width: '100%',
  position: 'absolute',
  top: 0,
};

const linearProgress = {
  height: '7px',
};

const styles = { box, linearProgress };
export default styles;
